<template lang="pug">
  CaseForm(:data="data")
  //- pre {{ data }}
</template>

<script>
import CaseForm from './CaseForm'

export default {
  name: 'CaseAdd',
  components: {
    CaseForm
  },
  data () {
    return {
      data: {
        id: undefined,
        thumbnail: undefined,
        logo: undefined
      }
    }
  },
  computed: {},
  methods: {},
  created() {}
}
</script>

<style lang="less" scoped></style>